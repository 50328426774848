var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"items":_vm.disciplines,"headers":[
      { text: 'Gerät', value: 'name', sortable: false },
      { text: 'Reihenfolge', value: 'order', sortable: false, align: 'center' },
      { text: 'Hinzufügen', value: 'add', sortable: false, align: 'center' },
      { text: 'Entfernen', value: 'del', sortable: false, align: 'center' }
    ],"items-per-page":-1,"mobile-breakpoint":0,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.order",fn:function(ref){
    var item = ref.item;
return [_c('base-edit-dialog',{attrs:{"value":item.order,"id":item._id,"label":"Reihenfolge"},on:{"input":function (ref) {
        var value = ref.value;
        var iid = ref.id;

        return _vm.updateorder(iid, value);
    }}})]}},{key:"item.add",fn:function(ref){
    var item = ref.item;
return [(!item.active)?_c('v-btn',{attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.add(item._id)}}},[_c('v-icon',[_vm._v("far fa-plus")])],1):_vm._e()]}},{key:"item.del",fn:function(ref){
    var item = ref.item;
return [(item.active && !item.used)?_c('v-btn',{attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.del(item._id)}}},[_c('v-icon',[_vm._v("far fa-minus")])],1):_vm._e()]}}])}),_c('h3',[_vm._v("Hinweise:")]),_c('ul',[_c('li',[_vm._v("Geräte, an denen schon mindestens 1 Turner aufgestellt wurde, können nicht mehr entfernt werden.")]),_c('li',[_vm._v("Um ein Gerät löschen zu können, müssen zunächst alle Turner von diesem Gerät abgemeldet werden.")]),_c('li',[_vm._v("Die Reihenfolge kann durch einen Klick auf die Zahl angepasst werden.")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }